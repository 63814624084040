.cards_wrapper {
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 30px; 
   
}
.cards_body_holder {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
    gap: 30px;
    width: 85vw;  
}
.cards_holder {
   display:flex;
   flex-direction: row;  
  
   
    gap:30px;
    min-width:600px;
    min-height: 400px;
}
.card {
    position:relative;
    max-width: 650px;
    min-width: 400px;
    min-height: 450px;
    height: fit-content;
    border-radius: 30px;
    background-color:#c774ffd4;   
    padding: 20px;
    padding-bottom: 55px;
    box-shadow:  2px 5px 2px 0 rgb(172, 167, 167);
    text-shadow: 1px 1px rgb(77, 75, 75);
    color: #FFFFFF;   
}
.card_upper_box {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:flex-start;
    margin-bottom: 30px;
}
.card_avatar_box {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color:var(--hb-bg-3);
   
}
.card_name_box {
    width: 70%;
    text-align: left;
    margin-left: 30px;
    font: var( --hero-font-style) ;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px; 
    text-wrap: break-word;  
   
}
.card_lower_box {
    font: var(--hero-card-style);
    font-size: 20px;
    font-weight:normal;
    text-align: left;
    margin-left: 10px;
   
}
.card_img {
    border-radius:50%;
    height: 140px;
    width: 140px;
    object-fit: cover; 
}
.indi_box {
    margin-top: 30px;
}
.card_audio_box {
    min-height: 200px;
    display: flex;
    align-items:center;
    justify-content: center;
}

.post_img_open {
    object-fit: cover;
    max-height: 400px;
    max-width: 500px;
    border-radius:10px;
    box-shadow:  1px 2px 1px 0 rgba(178, 137, 214, 0.676);
}



.mob_rmv_btns {
    display: block;
    height: 100%;
    margin-top: 20vh;
}




.mob_add_btns {
    display: none;
}
.vid_player_container {
    position: absolute;
    top:5vh;
    left:auto;
    height: 80vh;
    z-index: 5;
}
.vid_btn_container {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background-color:var(--btn-bg-color);
    display:flex;
    align-items:center;
    justify-content: center;
    outline: 1px solid var(--btn-outline-color);
}


@media screen and (max-width: 1400px) {
    .cards_holder {
        min-width:auto;
        width: 70vw;
        min-height: 400px;
        display:flex;
        align-items:center;
        justify-content: center;
    }
}

@media screen and (max-width: 850px) {
    .cards_body_holder {     
        flex-direction: column;
        width: 98vw;
        align-items: center;
    }
    .mob_rmv_btns {
        display: none;
    }
    .mob_add_btns {
        display: flex;
        gap:10vw;
    }
}

@media screen and (max-width: 620px) {
    
   
    .card {
        max-width: 650px;
        min-width: auto;      
        padding: 20px;
        margin-right: 20vw;
    }



.card {
    width: 90vw !important;
    min-width: auto;      
    min-height: 450px;
    height: fit-content;   
    padding: 20px;
 
     margin: 0 auto;
     
}
.card_upper_box {
flex-direction: column;
justify-content: center;
align-items: center;

margin-left:0px;
/* padding-right: 50px; */
padding-left: 0px;
}
.card_name_box {
    width: 100%;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
}
.card_img {
    border-radius:20px;
    height: auto;
    width: 50vw;
 }   
.card_avatar_box {
    height: fit-content;
    min-width: 140px;
    border-radius: auto;
    margin-right: 14vw;

}
   
}
