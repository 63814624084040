.home_screen_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;    
    position: relative;
    overflow: hidden !important;     
}
.logo_container {
    width: 100vw;
    height: 100px;
    margin-top: 55px;
    margin-bottom: 10px;
    padding-left: 30px;
    display: flex;    
    align-items:center;
    justify-content: center;
    font-size: var(--txt-bold);
    color: var(--yellow-txt);
    font-weight: 700;
}
.logo_container_motion {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
}
.home_screen_body_holder {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-size: var(--txt-midi);
}
/* .home_screen_options_container {
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    justify-content: flex-start;    
    min-height: 100%;
    width: 120px;
    border-radius: 0 15px 0 0 ;
    padding-right: 20px;   
} */
.home_screen_body_txt {
    width: 100%;
    min-height: auto;
    overflow-y: scroll;    
    padding: 0 10vw 210px 10vw; 
    
}

.home_screen_article_container {
    display: flex;
    flex-direction: column;  
}
.home_screen_article_containe_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:10px;
}
.home_screen_body_txt h3, h4 {
    color: var(--yellow-txt);
    line-height: 35px;
    margin-top: 20px;
}

.home_screen_article {
    width: 500px;
    margin: 20px;
    text-align: justify;  
}
.img_wrapper {
    height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
}


.flags_container {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    /* background-color: aliceblue; */
   
}
.flag_item1 {
    background-image: url('../../src/assets/uk_round.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 30px;
    height: 30px;
    border-radius:50%; 
    cursor: pointer;
    margin: 0 20px;
}
.flag_item2 {
    /* background-image: url('../../src/assets/ukr_round.png');
    -webkit-background-size: cover; */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 30px;
    height: 30px;
    border-radius:50%; 
    cursor: pointer;
    margin: 0 20px;  
    overflow: hidden; 
    background-color:rgb(237, 237, 14);
}
.flag_item2_cl1 {
    background-color: rgb(0, 0, 227);
    width: auto;
    height: 50%;
}
.flag_item3 {
    
    /* background-image: url('../../src/assets/uk_round.jpeg');
    -webkit-background-size: cover; */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 30px;
    height: 30px;
    border-radius:50%; 
    cursor: pointer;      
    overflow: hidden;
}
.flag_item3_cl3{
    background-color:red;
    width: auto;
    height: 36%;
}
.flag_item3_cl1{
    background-color:white;
    width: auto;
    height: 32%;
}
.flag_item3_cl2{
    background-color:green;
    width: auto;
    height: 32%;
}
.lang_intro_wrapper {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin: 30px 0 0 35px;
}
.lang_intro_description {
    margin-top: 15px;
}


.italic_txt {
    font-style: italic;
}
.link {
    font-size: var(--txt-key_board);
    color: var(--wh-text);
    margin-bottom: 20px; 
    cursor: pointer;
}
/* .link:hover {   
    text-decoration: underline;
    text-underline-offset: 7px;    
    transform: scale(1.05) translateY(-3px);
} */
.no_sound {
    color:rgb(198, 198, 198);
    cursor:not-allowed;
    font-size: large;
   }

   .rouned_btn {
   margin-left: 10vw;   
}

.note_notice_txt {
    font-size: var(--txt-bold-gap);
    font-weight: 400;
}




.abs_div {
    position: absolute;
    top: 30px;
    left: 30px;   
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 5;
}
.abs_btn {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
}
.abs_lang_select {
    position: fixed;
    top: 10px;
    right: 0px;
    z-index: 5;
}
.user_div {
    flex-direction: column;
    gap:8px;
    justify-content:flex-start;
    font-size: 20px;
}



@media screen and (max-width: 900px) {
   .logo_container_motion {   
     flex-direction: column;   
   }
}









/* .home_position_pic {
    background-image: url('../../src/assets/home_position.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 700px;
    height: 250px;
    border-radius:10px;
    margin: 80px 0 0 30px;
} */
/* .touch_typing_pic {
    background-image: url('../../src/assets/touch_typing.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 700px;
    height: 500px;
    border-radius:10px;
    margin: 80px 0 0 30px;
}
.no_net_pic {
    background-image: url('../../src/assets/no_net.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
    width: 360px;
    height: 300px;
    border-radius:10px;
    margin: 50px 0 0 30px;
} */