
  
  .modal__footer {
   background-color:aquamarine;
  }
  .btn {
    border-radius: 10px;
  }
  .vid_window {
    height: 100%;
    padding: 10px;
    max-height: 85vh; 
    max-width: 95%;
   } 
.crd_img {
  max-height: 95vh;
  margin: 10px 0;
  border-radius: 10px;
  max-width: 95%;
} 
.carusel {
  height: 99%;
}
#cover2 {
  margin: 5% 1%;  
  border-radius: 10px; 
  max-width: 98%;
}

  @media (max-width: 1300px) {  
   .vid_window {
    width: 100%;
    height: auto;
    max-height: 85vh; 
   }  
   .crd_img {
    max-width: 100%;  
   
  } 
  
}
