.slider_wrapper {
    width: 100vw;
    max-width: 1580px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} 
.slider_btn_cont {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.btn_bg {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background-color:var(--btn-bg-color);
    display:flex;
    align-items:center;
    justify-content: center;
    /* outline: 1px solid var(--btn-outline-color); */
}
.slider_btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--hb-bg-1); 
    cursor: pointer; 
}
.slider_btn:active {
    transform: translateY(5px);
    background-color: var(--green);
    box-shadow: 1px 1px 0 0 var(--light-bg);
    transition: all 200ms ease-in-out;
}
.slider_indicator_item:active {
    background-color: var(--green);
    box-shadow: 0 2px var(--light-bg);
    transform: translateY(2px);
    transition: all 300ms ease-in-out;
  }


.left_btn {
    transform: rotate(90deg)
}
.right_btn {
    transform: rotate(-90deg)
}
.slider_body {
    width: 100%;
  color:black;
}
.slider_display {
    height: 100%;
    min-height: 85vh;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    color: black;
    padding: 20px;  
    border-radius: 20px;
}
.slider_item {   
    justify-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    max-width:30vw;
    max-height: 40vh; 
    background-color:  var(--btn-bg-color); 
    border-radius: 20px;
}
.slider_img {
   
   max-width:100%;
   max-height: 100%;
   border-radius:5px;
   object-fit: cover;
   box-shadow: 2px 2px 2px 0 rgba(128, 128, 128, 0.444);
   transition: all ease-in-out;
   animation: fade-in ease-in 0.5s;
}

.slider_indicators_box {
    color:black;
    padding: 20px;
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    max-width: 1400px;  
    transition: background-color 1.8s ease-in-out;
}
.slider_indicator_item {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: var(--hb-bg-3);
    margin-left: 7px;
    cursor: pointer;
    transition: background-color 2000ms ease-in-out;
}


.indicator_active {
    background-color: var(--hb-bg-1);
}
@keyframes fade-in {
    0% {
        opacity: 0.3;
        scale: 0.5;
    } 100% {
        opacity: 1;
        scale: 1;
    }
}


.tabs {
    color: var(--btn-color);
    display: flex;
    flex-direction: row;
    gap:30px;
    font-family: var( --hero-card-style);
    font-size: 20px;
    
}

.tab_item {
    outline: 2px solid var(--btn-color);
    padding: 10px 30px;
    border-radius: 35px;
    min-width: 200px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.tab_active {
    background-color: var(--btn-color);
    color: #FFFFFF;
    font-weight: bold;
}
.lower_btn {
    font-family: var( --hero-card-style);
    font-size: 20px;
    outline: 2px solid var(--btn-color);
    padding: 10px 30px;
    border-radius: 35px;
    min-width: 200px;
    cursor: pointer;
    background-color: var(--btn-color);
    color: #FFFFFF;
    font-weight: bold;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.slider_2_box {
    min-height: 60vh;
    margin: 7vh 0;
    color:black;
    position:relative;
    
}
.slider2_container {
   
    min-width: 1070px;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    margin-bottom: 3vh;
   
}
.slides_2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
     justify-content: space-around;
}
.slider2_item {
    min-height: 580px;
    width: 315px;
    outline: 10px solid var(--hb-bg-1);
    border-radius:30px;
    position: relative;
}
.slider2_item_top {
    height: 12px;
    width: 100px;
    background-color: var(--hb-bg-1);
    border-radius:0 0 10px 10px ;
    position: absolute;
    top: -3px;
    left: calc(50% - 50px)
}
.mid_itm {
    width: 150px;
    left: calc(50% - 75px)
}
.slider2__side_item {
    min-height: 500px;
    width: 240px;
}
.slider2_center_item {
    z-index: 2;
}
.mob_rmv_btns {
  display: block; 
}
.mob_add_btns {
      display: none;  
}

@media screen and (max-width: 1400px) {
   .slider_display {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
   }
   .slider_wrapper {
    width: 95vw;
    max-width: auto;
} 
.slider_display {
    min-height: auto;
   
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns:  1fr 1fr;   
}
.slider_body {
    width: 80vw;
}
.tabs { 
    display: grid;      
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; 
    gap:20px 30px;                
}
}

@media screen and (max-width: 912px) {
   
    .slider_display {
        grid-template-columns: 1fr;
        gap:40px ;  
    }
    .slider_body {
        width: 60vw;
    }
    .mob_rmv_btns {
        display: none;
    }
    .mob_add_btns {
        display: flex;
        gap:10vw;
    }
    .slider_wrapper {
        flex-direction: column;
    }
    .slider_img {
        max-width:90vw;
        max-height: 100%;
       
     }
   
}

@media screen and (max-width: 430px) {
    .tabs {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr ;                       
    }
    .slider_indicators_box {
        max-width: auto;
       
    }
    .slider_indicator_item {     
        height: 10px;
        width: 10px;
        border-radius: 50%;       
        margin-left: 4px;
    }
   
}

@media screen and (max-width: 620px) {
    .tabs {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap:30px 40px;                
    }
   
}
