.cards_container {
    width: 50vw; 
    /* background-color:antiquewhite; */
}

.page_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; 
   
}

.cards_wrapper {
    width: 100%;
    padding-top: 10px; 
    font-size: larger;  
}


.cards_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items:center;
    margin-bottom: 7px;
    padding: 10px 15px;
    font-weight: bold;
}

.card_item_cont {
  
}
.card_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    background-color:var(--notification-bg);
    border-radius:var(--border-rad);
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 7px;
    transition: 0.8s ease-in-out; 
    line-height: 30px;  
   
}
.card_item:hover {
    transform: scale(1.10) translateX(3px) !important;    
}
.card_upper_part {
    display: flex;
    flex-direction: row;
    gap: 2vw; 
 }

.card_msg_cont {
    margin-left: 20px;
    width: 70%;
    padding-right: 10px;
}
.card_user_name {
    font-weight: bold;
    letter-spacing: 3px;
    text-align: left;
}
.card_user_name_2 {
 margin-bottom: 3vh;
 margin-left: 2vw;
}
.card_media_cont {
    width: 100%;
   padding: 25px 0 2px 0; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    gap: 30px
}
.card_media_cont_open {
    width: 130px;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
}

.card_header_letter {
    width: 15%; 
}

.card_header_result_wrapper {
     width: 50%;
}
.card_header_result_topper {
    border-bottom: 1px solid;
    padding: 5px;
    width: 95%;
    margin-left: 10px;
}
.card_header_result_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.card_item_result {
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.open_view_item {
    position: fixed;
    min-height: 90vh;
    width: 90vw;
    top: 6vh;
    left: 5vw;
    cursor:auto;
    z-index: 10;
    align-items: start;
    padding-top: 2vh;
    
}
.open_view_item:hover {
    transform:none !important;
}
.x_icon {
   position:fixed; 
   top:8vh;
   left:91vw;   
   cursor: pointer;
   z-index: 2;
}
.trash_bin {
   position: absolute; 
   bottom: 15px;
   right: 20px;   
   cursor: pointer;
   z-index: 2; 
   background-color: var(--btn-outline-color);
   height: 45px;
   width: 40px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.media_container {
    width: 100%;
   
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    gap:20px;  
}
.media_container_row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
}

.card_img_cont {
    border-radius:50%;
    width: 130px;
    height: 130px;
    position: relative;
    background-color:aquamarine;
    background-size: cover;
    background-position: center;
}
.user_img {   
    border-radius:50%;
    height: 130px;
    width: 130px;
    object-fit: cover;
}
.post_img {
    height: 130px;
    width: 130px;
    object-fit: cover;
}
.post_img_open {
    object-fit: cover;
    height: 200px;
}

@media screen and (max-width: 900px) {
    .cards_container {
        width: 90vw;
    }
    .card_upper_part {
        flex-direction: column;
    }
    .x_icon {      
        left:85vw;
     }
     .card_item {
        flex-direction: column;
     }
     .card_media_cont {
        flex-direction: row;
        justify-content: space-around;
        margin-top: 20px;
        padding: auto;
        width: 100%;
     }
     .media_container_row {
        flex-direction: column;
     }
     .card_msg_cont {
        width: 100%;
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       align-items: center;
     }
     .open_view_item {
        position: sticky;
        justify-content:start; 
        height: fit-content;  
         
        z-index: 100;   
     }
     .card_msg_cont {
        margin-top: 2vh;
        margin-left: 0;
     }    
     
 }

 .button {
    width: 80px;
    height: 30px;
    /* background-color:aqua; */
    border-radius: 20px;
    color:cadetblue;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;
    outline: 2px solid;
 }
 .button:focus {
color: #000;
 }
 .button_add_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    margin: 15px 0;
   
 }
 .button_add {
    margin-top: 15px;
    height: 40px;
    width: auto;
    color:aliceblue;
    font-weight: 500;
 }
