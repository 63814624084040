.App {
  width: 100vw;
  height: auto;
  text-align: center;
  color: #ffffff; 
  position: relative;
}







