.df_c_c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.screen_wrapper {
    width: 100vw;
    height: 100%;
    min-height: 100vh;    
}
.screen_max_w {
    max-width: 1441px;
}
.screen2_header {
 
  padding: 10vh 0;
  width: 100vw;
  
}
.header_txt_box {
  font-family: var(--hero-font-style);
  font-size: 30px;
  font-weight: 300;
  color:black;
  padding: 5vh 0 7vh 0;
}
.photo_line_img {
    width: 100%;
    object-fit: cover;
}

.screen2_body {   
    flex-direction:row;
    justify-content: flex-start;
    width: 100vw;
    max-width: 1441px;
}
.body_l_side {
    width: 60%;   
    text-align: left;
    padding-left: 2vw;
    font-family: var( --hero-card-style);   
    color:black;
}
.body_l_side_header {
    line-height: 40px;   
    font-size: 30px;    
}
.body_l_side_txt {
    line-height: 25px;   
    font-size: 20px;  
}
.hero_heart {
    position: relative;  
}
.cup_img {
    position: absolute;
    left:36px;
    top:38px;
}
.screen_33_body {
    flex-direction:column;
    justify-content: flex-start;
    width: 100vw;
}

.screen_33_bg {
    background-image: url('/src/assets/block5-bg-img.png');
    /* background-position:bottom; */
    max-width: 2200px;
    background-size:cover;
    min-height: 120vh; 
}
.lower_btn_cont {
  
}

.screen4_body {
    margin: 5vh 0;
   
    max-width: 1440px !important;
    min-height: 750px;
    background-color:var(--hb-bg-1);
    border-radius:20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:flex-start;
    text-shadow: 1px 1px rgb(77, 75, 75);
    
}
.screen4_txt_box {
    width: 55%;
    height: 100%;      
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    text-align: left;
    padding: 30px 30px 20px 0;
    font-family: var( --hero-card-style);   
    color:#FFFFFF;
    margin: auto 0;
   }
.screen_4_bg {
    background-image: url('/src/assets/the-cup.png');
    /* background-position:bottom; */
    width: 100% ;
    background-size:cover;
    background-position: bottom left;
    
}
.screen4_txt_header {
    line-height: 32px;   
    font-size: 26px; 
}
.screen4_txt_body {
    margin-top: 20px;
    line-height: 25px;   
    font-size: 22px; 
    padding-right: 20px; 
}
.screen4_mob_div {
    display: none;
}
.plane_mob {
    display: none;
}

@media screen and (min-width: 1440px) {
    .body_l_side {       
        padding: 0 0 0 0;
    }
}

@media screen and (max-width: 1300px) {
    .screen4_mob_div {
        display:block;
        width: 100%;
        height: calc(100vw / 1.2);
        background-image: url('/src/assets/cup_mobile.png');        
        width: 100% ;
        background-size:contain;
        background-position: bottom center;
        background-repeat:no-repeat;
       }
       .screen_33_bg {
        background-image: url('/src/assets/slider_fon.png');
        /* background-position:bottom; */
        max-width: 100vw;
        background-size:cover;
        min-height: auto; 
        position: relative;
    }
       .screen_4_bg {
        /* display:none; */
        background-image:none;
     }
     .screen4_txt_box {
        width: 95%;        
        padding: 30px;        
       }
       .scree4_mob_adjust {
        flex-direction: column !important;
       }
       .plane_mob {
        display: block;
        position: absolute;
        top: -50vh;
        right: 0px;
        height: 100px;
        width: 120px;       
        background-image: url('/src/assets/airplane.png');
        background-repeat: no-repeat;       
    }
   
}

@media screen and (max-width: 912px) {
    .screen2_header {
        padding: 10vh 0 0 0;
    }
    .screen2_body {   
        flex-direction:column-reverse;       
    }
    .body_l_side {
        width: 90%;
    }
    .body_r_side {
        width: 80%;
    }
    .fx_adjust {
        flex-direction:column;
    }
    .plane_mob {
        display: block;       
        top: -50vh;
    }
      
}