.plus {
    font-size: large;
}
.modal {
   background-color:var(--hb-bg-1); 
} 
.modal_header {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
    font-size: large;
    font-weight: bolder;
    background-color:var(--hb-bg-1);
}
.modal_form {
    text-align:center;   
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
    padding: 0px !important;
    min-height: 430px; 
} 
.modal_footer {
    background-color: var(--hb-bg-1);
}


.alert {
    text-shadow: none;
    font-weight: bold;
    font-family: Roboto;    
}

.img_picker_descr {
    text-align: left;
    font-size: small;
}
.alert_div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.empty {
   outline: 3px solid red; 
}

.img_btn_cont { 
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 25px;
}
.img_btn_cont_subdiv {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lower_btn {
    font-family: var( --hero-card-style);
    font-size: 20px;
    outline: 2px solid var(--btn-color);
    padding: 10px 30px;
    border-radius: 35px;
    min-width: 300px;
    cursor: pointer;
    background-color: var(--btn-color);
    color: #FFFFFF;
    font-weight: bold;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    text-transform: none;
}