.disp_flx_ctr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home_screen_wrapper {
    width: 100vw;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;    
    position: relative;
    overflow-x: hidden !important; 
    padding: 0px 30px 40px; 
     
}
.screen_fill {
    width: 100vw !important; 
    background-color:var(--hb-bg-1) ; 
}

.screen_wrapper {
    width: 80%;
    height: fit-content;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.screen1_adjuster {
   height: 90vh;
   width: 100%;   
    padding-top: 5vh;
    display: flex;
   flex-direction: row;
   position: relative;  
}
.hero_l_side {
  min-width: 52%;
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
   padding-top: 15px;
}
.hero_r_side {
  width: 100%;
}
.sc1_cont {
    min-height: 95vh;
  
}
.hb_container_motion {
    margin-top: 10vh;
    font-size: var(--txt-black-gap);
    position: relative;
    margin-bottom: 15px;
    z-index: 1;
    text-shadow: 1px 1px rgb(77, 75, 75);
}
.hero_heart {
  position: absolute;
  top: -18px;
  left: 240px;
 height: 46px;
  width: 92px;
  border-radius:20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(15deg);
}
.hero_karateka {
  transform: rotate(-10deg);
  left: -50px;
  top:-50px;
}
.hero_heart_img {
  height: 45px;
  object-fit: cover;
  border-radius:20px; 
}
.hero_pins {
  position: absolute;
  top:-30px;
  right: -30px;  
}
.hero_bPp {
  top:-40px;
  left: -50px;
}
.hero_yPp {
  top:-50px;
  right: -50px;
}
.hero_pClip_img {
  height: 91px;
}
.sc1_intro {
    font-size: var(--txt-intro);
   padding-top: 20px;
}

.sc5_container {
  height: fit-content;
  min-height: 30vh;
  padding: 8vh 2vw;
  font-size: var(--txt-intro);
  color: navy;
  font-weight: 500;
}



.hero_txt {
  font-family: var(--hero-font-style) ;
  font-size: 60px;
  text-align: left;
  line-height: 70px;
  font-weight: 300;

 }
 .hero_txt_fill {
  color: var(--hero-font-fill) ;
 }
 .ballast {
  height: 14px;
 }
.hero_white_card {
  min-width: 535px;
  min-height: 170px;
  background-color: var(--wh-text);
  color: black;
  font-family: var(--hero-card-style);
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  padding: 20px 30px;
  border-radius: 25px;
  transform: rotate(1deg);
  margin-top: 40px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
}
.hero_white_card2 {
  transform: rotate(-1deg);
  margin-top: 0px;
  box-shadow: 1px 0px 0px 1px rgba(0, 0, 0, 0.116);
}
.hero_white_card3 {
  transform: rotate(3deg);
  margin-top: 0px;
  box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.116);
}

.bg_purple {
  background-color: var(--hb-bg-1);
  color: var(--wh-text);  
}

.btm_arrow {
  position: absolute;
  bottom: -33px;
  left: calc(50vw - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color:var(--hb-bg-1);
  padding-top: 40px;
 
}


.hero_r_side {
  width: 100%;

  /* background-image: url('/src/assets/hero_r_side.png');
  background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    display: flex;
    justify-content: center;
    align-items: center; 
   
}
.hero_rside_img {
  /* position: absolute; */
  width: 100%;
  height: 100%;

 position: absolute;
 left: -10px;
 top:0px;
}
.hero_img_mobview {
  display: none;
}


@media screen and (max-width: 912px) {
    .screen1_adjuster {
        height: auto;
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; 
        padding-bottom: 50px;
        padding-top: 0vh;
     
       
    }
    .hero_l_side {
      padding-left: 0px;
      align-items:center;
      justify-content: flex-start;
    }
    .hb_container_motion {
      margin-top: 0vh;
      margin: 5vh 0;
  }
    .home_screen_wrapper {     
        height: auto;         
    }
    .hero_txt {
      text-align: center;
      font-size: 36px;
      line-height: 43px;      
    }
    .hero_r_side {
   display: relative;
      width: 100%;
      height: 100%;
      height:35vh;
    
      /* background-image: url('/src/assets/hero_r_side.png');
      background-size:cover;
       background-position: bottom right;
       background-repeat:no-repeat; */
    }
    .hero_img_mobview {
      display: block;
      margin: 4vh 100vw 0 0;
      aspect-ratio: 3.5;
      width: 200vw;
      
    }
    .screen_fill {     
      background-image: url('/src/assets/hero_fon.png');
      background-size:cover;
       background-position: bottom -7vh center;
       background-repeat:no-repeat;      
    }
    .btm_arrow {
      z-index: -1;
    }
    .hero_rside_img {
      display: none;
    }
   
 }

 @media screen and (max-width: 555px) {
  .hero_white_card {
    width: 95vw !important;
    min-width: auto;
    height: auto;
    font-size: 16px;
   
    padding: 15px 10px;
    margin-top: 40px auto;    
  
  }
  .screen1_adjuster {
    /* overflow: auto; */
  }
  .screen_fill { 
  
     background-position: bottom -9vh left;
     background-repeat:no-repeat; 
  } 
  .hero_txt {
    word-wrap: break-word; 
    hyphens: auto;
    overflow-wrap: break-word; 
  }
  .hero_l_side {
    /* padding-left: 25vw; */
    /* width: 100vw ;
    height: auto;   
    line-height: 24px;
    padding: 20px 30px;
      
    box-sizing: border-box;
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    hyphens: auto; */
    margin-top: 40px auto; 
  }
 }


 


